import Vue from "vue";
import Component from "vue-class-component";
import { Emit, Prop } from "vue-property-decorator";
import {
  DgButton,
  DgColumn,
  DgDatePicker,
  DgGridContainer,
  DgRow,
  DgScore,
  DgSeparator,
  DgText,
  DgTextInput,
} from "@dasgate/uikit";
import { PortrayalResult } from "@/core/portrayal/domain/portrayal";

@Component({
  components: { DgColumn, DgText, DgSeparator, DgRow, DgScore, DgTextInput, DgDatePicker, DgButton, DgGridContainer },
})
export default class PortrayalResultForm extends Vue {
  @Prop({ required: true }) public portrayalResult!: PortrayalResult;
  @Prop({ required: true }) public validationErrors!: Record<string, string>;
  @Prop({ required: true }) public canSubmit!: boolean;

  public get allowEditing(): boolean {
    if (this.portrayalResult.scores.dataValidationOk === undefined) {
      return !this.portrayalResult.scores.documentOk;
    } else {
      return !this.portrayalResult.scores.documentOk || !this.portrayalResult.scores.dataValidationOk;
    }
  }

  public get selfieVsPhotoScore(): string {
    if (this.portrayalResult.scores.selfie === -1) {
      return "indeterminate";
    }
    return this.portrayalResult.scores.selfieOk ? "passing" : "failing";
  }

  public get documentScore(): string {
    if (this.portrayalResult.scores.document === -1) {
      return "indeterminate";
    }
    return this.portrayalResult.scores.documentOk ? "passing" : "failing";
  }

  public get noOcrText(): string {
    if (this.documentScore === "indeterminate" && this.selfieVsPhotoScore === "indeterminate") {
      return this.$t("common.onboarding.register-finish.no-ocr-no-photo-message") as string;
    } else if (this.documentScore === "indeterminate") {
      return this.$t("common.onboarding.register-finish.no-ocr-message") as string;
    }
    return "";
  }

  @Emit() onAccept() {
    return;
  }

  @Emit() onCancel() {
    return;
  }
}
