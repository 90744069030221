import Vue from "vue";
import { DynamicRegisterType } from "./settings";
import { RegisterType } from "./shared";
import { FieldValidator } from "./validators";

export default class DynamicForm extends Vue {
  private registerType: RegisterType;
  private validator = new FieldValidator();

  constructor(registerType: RegisterType) {
    super();
    if (this.$appStore.getters.assistedEmail) {
      this.registerType = RegisterType.AssistedExistingUsers;
    } else {
      this.registerType = registerType;
    }
  }

  public get fields(): DynamicRegisterType[] {
    return this.$appStore.getters.settings.registerTypes[this.registerType];
  }

  public get requiredFields(): string[] {
    const filteredFields = this.$appStore.getters.settings.registerTypes[this.registerType].filter(
      field => field.required === true
    );
    return filteredFields.map(x => {
      return x.name;
    });
  }

  public findField(name: string) {
    return this.fields.find(x => x.name === name);
  }

  public async validate(field: string, value: any): Promise<string> {
    const dynamicField = this.fields.find(x => x.name === field);
    if (dynamicField?.required && (value === undefined || value === "")) {
      return "common.errors.required";
    }
    if ((value && value.length) || value instanceof Date) {
      if (dynamicField?.regex?.length) {
        const regexp = new RegExp(dynamicField.regex);
        if (!regexp.test(value)) {
          return "common.errors.regex";
        }
      }

      if (dynamicField?.required && value.length == 0) {
        return "common.errors.required";
      }

      if (dynamicField?.validate_function?.length) {
        return this.validator[dynamicField.validate_function](value);
      }
    }
    return "";
  }
}
