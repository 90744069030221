import Vue from "vue";
import Component from "vue-class-component";
import moment from "moment";
import { DgButton, DgColumn, DgLoading, DgSeparator, DgText } from "@dasgate/uikit";
import { RouteNames } from "@/ui/_router";
import {
  cancelOnboarding,
  ConfirmOnboardingResponseErrorCode,
} from "@/legacy/features/onboarding/models/onboarding.models";
import { PortrayalResult } from "@/core/portrayal/domain/portrayal";
import { isDefined, isUndefined } from "@/core/shared/utils";
import { ApiError, StatusCodes } from "@/core/shared/api/domain/apiClient";
import { ExecutorResult } from "@/ui/_utils/executor/useCaseExecutor";
import { PortrayalResultForm } from "./_components/PortrayalResultForm";
import { validationErrors } from "./PortrayalResult.model";
import { DocumentOcrData } from "@/legacy/models/shared";

@Component({
  components: {
    PortrayalResultForm,
    DgText,
    DgButton,
    DgColumn,
    DgSeparator,
    DgLoading,
  },
})
export default class PortrayalResultView extends Vue {
  public isConfirmOnboardingLoading = false;
  public portrayalResult: ExecutorResult<PortrayalResult | undefined> = { data: undefined, isLoading: true };

  private async onPortrayalResultSuccess() {
    if (this.isAutoRegisterMode && !this.userUnderScore && this.canSubmit) {
      try {
        await this.accept();
      } catch (error) {
        this.$services.errorsService.onError(error as Error);
      }
    }
  }

  public async mounted() {
    const navigateToError = (error: ApiError) => {
      this.$router.push({
        name: RouteNames.Error,
        params: { link: JSON.stringify(error.link), reason: error.data.reason },
      });
    };

    this.$execute(() => this.$container.portrayalUseCases.getResult(this.$appStore.getters.identityPortrayalId), {
      resultRef: this.portrayalResult,
      onSuccess: this.onPortrayalResultSuccess,
      errorDefinitions: {
        [StatusCodes.BAD_REQUEST]: navigateToError,
        [StatusCodes.CONFLICT]: navigateToError,
      },
      onError: () => {
        this.$router.push({
          name: RouteNames.FinishRegisterWithError,
          params: { errorCode: ConfirmOnboardingResponseErrorCode.LifeProofingFailed },
        });
      },
    });
  }

  public get isLoading(): boolean {
    return this.isConfirmOnboardingLoading || this.portrayalResult.isLoading;
  }

  public get canSubmit(): boolean {
    if (this.portrayalResult.isLoading) {
      return false;
    }

    if (this.$appStore.getters.documentType === "others") {
      return true;
    }

    const errors = Object.values(this.validationErrors);
    const thereAreErrors = errors.some(e => e !== "");
    return !thereAreErrors;
  }

  public get validationErrors(): Record<string, string> {
    if (this.portrayalResult.isLoading) {
      return {};
    }

    return validationErrors(this.portrayalResult.data!);
  }

  public get isAutoRegisterMode(): boolean {
    return this.$appStore.getters.registerMode === "auto";
  }

  // This only works when identity proofing
  public get userUnderScore(): boolean {
    return (
      this.isAutoRegisterMode &&
      isDefined(this.portrayalResult.data?.scores) &&
      (!this.portrayalResult.data!.scores.selfieOk || !this.portrayalResult.data!.scores.documentOk || !this.canSubmit)
    );
  }

  public get documentOcrData(): DocumentOcrData | undefined {
    const documentData = this.portrayalResult.data?.documentData;
    if (isUndefined(documentData)) {
      return undefined;
    }
    return {
      ...documentData,
      birthDate: moment.utc(documentData.birthDate).toDate(),
      expiryDate: moment.utc(documentData.expiryDate).toDate(),
    };
  }

  public async cancel(): Promise<void> {
    await cancelOnboarding();
  }

  public async accept(): Promise<void> {
    if (this.isAutoRegisterMode && this.userUnderScore) {
      this.$router.push({ name: RouteNames.DocumentScan });
      return;
    }

    if (this.formType === "Dynamic") {
      if (this.$appStore.getters.documentType !== "others") {
        localStorage.setItem("documentData", JSON.stringify(this.documentOcrData));
      }
      this.$router.push({ name: RouteNames.RegisterForm });
      return;
    }

    await this.confirmOnboarding();
  }

  private async confirmOnboarding() {
    const onboardingId = this.$appStore.getters.onboardingId;
    const identityPortrayalId = this.$appStore.getters.identityPortrayalId;
    const assistedEmail = this.$appStore.getters.assistedEmail;
    const data = {
      ...this.documentOcrData!,
      ...(assistedEmail && this.$appStore.getters.settings.biometricQR ? { email: assistedEmail } : {}),
    };

    this.isConfirmOnboardingLoading = true;
    const result = await this.$services.onboardingService.confirm({
      onboardingId,
      identityPortrayalId,
      data,
    });

    this.isConfirmOnboardingLoading = false;

    if (result.success) {
      if (this.$appStore.getters.biometricQR) {
        this.$router.push({
          name: RouteNames.FinishRegisterWithQr,
          params: {
            userId: result.userId,
            qrId: result.qrId ? result.qrId : "",
          },
        });
      } else {
        this.$router.push({ name: RouteNames.FinishRegister, params: { userId: result.userId } });
      }
      this.$appStore.actions.resetOnboarding();
    } else {
      if (result.retry) {
        this.$appStore.actions.setConfirmData(data);
      }
      this.$router.push({
        name: RouteNames.FinishRegisterWithError,
        params: { errorCode: result.code.toString() },
      });
      return;
    }
  }

  private get formType(): string {
    const mode = this.$appStore.getters.registerMode;
    let registrationForm = mode[0].toUpperCase() + mode.slice(1);
    const documentType = this.$appStore.getters.documentType;
    if (this.$appStore.getters.assistedEmail) {
      registrationForm = registrationForm + "ExistingUsers";
    }
    if (documentType === "others") {
      registrationForm = registrationForm + "OtherDocuments";
    }
    if (this.$appStore.getters.settings.registerTypes[registrationForm] !== undefined) {
      return "Dynamic";
    }
    return "Undefined";
  }
}
