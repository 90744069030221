import Vue from "vue";
import Component from "vue-class-component";
import { DgBox, DgButton, DgColumn, DgMarkdownText, DgRow, DgSeparator, DgText } from "@dasgate/uikit";
import { goToOnboarding, redirectUser } from "@/legacy/features/common/shared";
import { createPortrayal } from "@/legacy/features/onboarding/models/identity-portrayal.models";
import { getSupportedDocs } from "@/legacy/features/onboarding/models/onboarding.models";

@Component({ components: { DgText, DgButton, DgMarkdownText, DgColumn, DgRow, DgSeparator, DgBox } })
export default class AcceptTerms extends Vue {
  public privacyContent = "";
  private accepting = false;
  private assistedUserId = this.$appStore.getters.assistedUserId;
  private assistedEmail = this.$appStore.getters.assistedEmail;

  public mounted(): void {
    if (!this.assistedUserId) {
      this.$appStore.actions.resetOnboarding();
      this.$appStore.actions.cancelIdentityPortrayal();
    }

    this.$watch("$i18n.locale", () => {
      this.getContent();
    });
    this.getContent();
  }

  public get loading(): boolean {
    return !this.privacyContent || this.accepting;
  }

  public async back(): Promise<void> {
    await redirectUser();
  }

  public async accept(): Promise<void> {
    this.accepting = true;
    let userId;

    if (!this.assistedUserId) {
      const mode = this.$appStore.getters.registerMode;

      const user = await this.$services.userService.createAnonymousUser({
        email: "",
      });
      userId = user.userId;

      const response = await this.$services.onboardingService.start({
        mode,
        location: this.$appStore.getters.userLocation,
        userId,
      });
      localStorage.removeItem("documentData");
      const docs = await getSupportedDocs(response.supportedDocs);

      this.$appStore.actions.startOnboarding(
        response.onboardingId,
        response.registerType,
        response.lifeProofing,
        response.biometricQR,
        docs,
        userId,
        this.assistedEmail
      );
    } else {
      userId = this.assistedUserId;
    }

    if (!this.$appStore.getters.identityProofing) {
      await createPortrayal("none", "xx", userId);
    }

    goToOnboarding();
  }

  private async getContent() {
    this.privacyContent = await this.$services.onboardingService.getTermsOfUse(this.$i18n.locale);
  }
}
