import { SendSelfieErrors } from "@/core/portrayal/domain/portrayalErrors";

export const toFormData = (aliveRequest: any, challengeToken: string): FormData => {
  const formData = new FormData();

  const imageSelfie = makeFileFromStringData(aliveRequest.image);
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  if (window.DetectRTC.browser.isSafari) {
    formData.append("anchorImage", imageSelfie.file, "image." + imageSelfie.fileXt);
  } else {
    formData.append("anchorImage", imageSelfie.file);
  }

  let targetFrames = null;
  let targetVideo = null;

  if (aliveRequest.image_alive.needsEncoding) {
    targetFrames = JSON.stringify(aliveRequest.image_alive.recording.video);
    formData.append("targetFrames", targetFrames);
  } else {
    targetVideo = blob2File(aliveRequest.image_alive, "video", aliveRequest.image_alive.type);
    formData.append("targetVideo", targetVideo);
  }

  const annotations = blob2File(aliveRequest.webVTT, "vtt", "text/vtt");
  formData.append("annotations", annotations);
  formData.append("token", challengeToken);

  return formData;
};

const b64toBlobtoFile = (b64Data: string, contentType: string) => {
  contentType = contentType || "";
  const sliceSize = 512;

  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }
  let fileXt = "jpeg";
  const fileXtSplit = contentType.split("/");
  if (fileXtSplit.length > 1) {
    fileXt = fileXtSplit[1];
  }
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  if (window.DetectRTC.browser.isSafari || window.DetectRTC.browser.isEdge) {
    // Safari needs blob object instead of file
    const blob = new Blob(byteArrays, { type: contentType });
    return { file: blob, fileXt };
  }
  const file = new File(byteArrays, "tempfilename." + fileXt, { type: contentType });
  return { file, fileXt };
};

const makeFileFromStringData = (data: string) => {
  const block = data.split(";");
  // Get the content type of the image
  const contentType = block[0].split(":")[1]; // 'image/png'
  // get the real base64 content of the file
  const realData = block[1].split(",")[1]; // 'R0lGODlhPQBEAPeoAJosM....'
  // Convert it to a blob or a file to upload
  return b64toBlobtoFile(realData, contentType);
};

const blob2File = (blob: Blob, contentType: string, name: string) => {
  // Transform blob to file
  const file = new File([blob], name, { type: contentType });
  return file;
};

const errorKeyMap = {
  [SendSelfieErrors.BlurredImageError]: "common.onboarding.register-documents.scan-ko.blurred_image_error",
  [SendSelfieErrors.DocumentClassificationError]:
    "common.onboarding.register-documents.scan-ko.document_classification_error",
  [SendSelfieErrors.ValidasError]: "common.onboarding.register-documents.scan-ko.validas_error",
  default: "common.onboarding.register-documents.scan-ko.unknown",
};

const getBaseTranslationKey = (reason: string) => errorKeyMap[reason] ?? errorKeyMap.default;

export const getTitleKey = (reason: string) => `${getBaseTranslationKey(reason)}.title`;
export const getSubtitleKey = (reason: string) => `${getBaseTranslationKey(reason)}.subtitle`;
