import Vue from "vue";
import Component from "vue-class-component";
import { DgBox, DgButton, DgColumn, DgImage, DgRadioGroup, DgRow, DgSeparator, DgText } from "@dasgate/uikit";
import { SingleDocument } from "./_components/SingleDocument";
import { SeveralDocuments } from "./_components/SeveralDocuments";
import { Prop } from "vue-property-decorator";
import { ExclusiveDocTypes } from "@/legacy/models/settings";
import { createPortrayal } from "@/legacy/features/onboarding/models/identity-portrayal.models";
import { RouteNames } from "@/ui/_router";
import { cancelOnboarding } from "@/legacy/features/onboarding/models/onboarding.models";

@Component({
  components: {
    SingleDocument,
    SeveralDocuments,
    DgImage,
    DgText,
    DgButton,
    DgRadioGroup,
    DgColumn,
    DgRow,
    DgSeparator,
    DgBox,
  },
})
export default class DocumentSelection extends Vue {
  private get countryDocuments() {
    this.selectedCountry = Object.keys(this.supportedDocs)[0];

    return Object.values(this.supportedDocs)[0].map((value: string) => ({
      value,
      label: this.$t(`common.onboarding.register-documents.document-types.${value}`),
    }));
  }

  private async onCancel() {
    await cancelOnboarding();
  }
  @Prop({ required: false, default: "" }) public selectedCountry!: string;

  public documentType = "";
  private documentSDK = "/sdk/documents/VDDocument_3_6_1.js";
  private docLibeHelper = "/sdk/documents/docLibHelper/docLibHelper.js";
  private openCV = "/sdk/opencv/cv.js";
  private documentSDKLoaded = this.isSdkLoaded();
  private docLibeHelperLoaded = this.isSdkLoaded();
  private openCVLoaded = this.isSdkLoaded();

  public mounted() {
    this.loadScript(this.documentSDK, "document");
    this.loadScript(this.docLibeHelper, "doclib");
    this.loadScript(this.openCV, "cv");
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this;
    const documentHTMLElem: HTMLElement = document.getElementById("document") as HTMLElement;
    documentHTMLElem.onload = () => {
      self.documentSDKLoaded = true;
    };
    const doclibHTMLElem: HTMLElement = document.getElementById("doclib") as HTMLElement;
    doclibHTMLElem.onload = () => {
      self.docLibeHelperLoaded = true;
    };
    const cvHTMLElem: HTMLElement = document.getElementById("cv") as HTMLElement;
    cvHTMLElem.onload = () => {
      self.openCVLoaded = true;
    };
  }

  public get customImages() {
    return this.$appStore.getters.settings.customImages;
  }

  public get sdkLoaded(): boolean {
    return (this.documentSDKLoaded && this.docLibeHelperLoaded && this.openCVLoaded) as boolean;
  }

  public get supportedDocs(): { [key: string]: string[] } {
    return this.$appStore.getters.supportedDocs;
  }

  public get availableDocuments(): string[] {
    const exclusiveDoc = this.$appStore.getters.settings.exclusiveDoc;
    if (exclusiveDoc !== ExclusiveDocTypes.idCard) {
      return ["idCard", "passport"];
    }

    this.documentType = "id_card";
    this.selectedCountry = "es";
    return [exclusiveDoc];
  }

  public get isAssistant(): boolean {
    return this.$appStore.getters.isAssistant;
  }

  public setDocumentType(event: string) {
    this.documentType = event;
  }

  public async selectDocument(): Promise<void> {
    if (!this.documentType) {
      return;
    }
    let userId;
    if (this.isAssistant) {
      userId = this.$appStore.getters.assistedUserId;
    } else {
      userId = this.$appStore.getters.userId;
    }
    await createPortrayal(this.documentType, this.selectedCountry, userId);
    this.$router.push({ name: RouteNames.DocumentScan });
  }

  private isSdkLoaded() {
    try {
      if ((window as any).vddocument.getSDKversion) {
        return true;
      }
    } catch {
      return false;
    }
  }

  private loadScript(src: string, name: string) {
    if (document.getElementById(name)) {
      return;
    }
    const script = document.createElement("script");
    script.setAttribute("src", src);
    script.setAttribute("type", "text/javascript");
    script.setAttribute("id", name);
    document.head.appendChild(script);
  }
}
