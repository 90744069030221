import { render, staticRenderFns } from "./DocumentSelection.vue?vue&type=template&id=e4d24612&"
import script from "./DocumentSelection.ts?vue&type=script&lang=ts&"
export * from "./DocumentSelection.ts?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports