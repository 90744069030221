export type PortrayalProcess = "accredit" | "onboarding";

export enum PortrayalType {
  Selfie = "selfie",
}

export interface PortrayalResult {
  documentData?: DocumentData;
  scores: PortrayalScores;
}

export interface DocumentData {
  firstName: string;
  lastName: string;
  documentId?: string;
  documentNumber?: string;
  expiryDate: string;
  birthDate: string;
  expeditionCountry: string;
  nationality: string;
  documentType: string;
  email?: string;
}

export interface PortrayalScores {
  selfie: number;
  selfieOk: boolean;
  dataValidationOk: boolean;
  document: number;
  documentOk: boolean;
  selfieAuthentic: boolean;
}
