import Vue from "vue";
import Component from "vue-class-component";
import {
  DgButton,
  DgColumn,
  DgDynamicInput,
  DgFlex,
  DgGridContainer,
  DgRow,
  DgSelect,
  DgSeparator,
  DgStepper,
  DgText,
  DgTextButton,
  DgTextInput,
  DynamicField,
  SelectOption,
} from "@dasgate/uikit";
import DynamicForm from "@/legacy/models/dynamicForm";
import { DynamicData, RegisterType } from "@/legacy/models/shared";
import EventBus, { EnumEventBus } from "@/legacy/services/bus.service";
import { RouteNames } from "@/ui/_router";
import { getStepper } from "@/ui/Onboarding/Tutorial/Tutorial.model";
import { OnboardingSteps } from "@/legacy/models/settings";
import { cancelOnboarding } from "@/legacy/features/onboarding/models/onboarding.models";
import { isUndefined } from "@/core/shared/utils";

@Component({
  components: {
    DgColumn,
    DgText,
    DgButton,
    DgTextButton,
    DgRow,
    DgGridContainer,
    DgFlex,
    DgTextInput,
    DgSeparator,
    DgSelect,
    DgDynamicInput,
    DgStepper,
  },
})
export default class RegistrationForm extends Vue {
  public dynamicForm = new DynamicForm(this.registrationForm);
  public validationErrors: { [id: string]: string } = {};
  public submitted = false;
  public form: Record<string, string> = {};

  public mounted() {
    EventBus.$on(EnumEventBus.serverErrors, (errors: { [id: string]: string }) => {
      Object.keys(errors).forEach(field => {
        this.$set(this.validationErrors, field, this.$tc(errors[field]));
      });
    });
  }

  get stepper() {
    return getStepper(this.$appStore.getters.settings.onboardingSteps, OnboardingSteps.Form);
  }

  get dynamicFields(): DynamicField[] {
    return this.dynamicForm.fields.map(
      field =>
        ({
          label: this.$t("common.fields." + field.label),
          uppercase: field.uppercase,
          placeholder: field.placeholder ? this.$t("common.placeholders." + field.placeholder) : "",
          autocomplete: false,
          error: this.validationErrors[field.name],
          name: field.name,
          type: field.type,
          required: field.required,
          max: field.max,
          timezone: isUndefined(field.timezone) ? "UTC" : field.timezone,
          selectOptions: this.getTranslatedOptions(field.selectOptions),
        } as DynamicField)
    );
  }

  get registrationForm(): RegisterType {
    const mode = this.$appStore.getters.registerMode;
    let registrationForm = mode[0].toUpperCase() + mode.slice(1);
    const documentType = this.$appStore.getters.documentType;
    if (this.$appStore.getters.assistedEmail) {
      registrationForm = registrationForm + "ExistingUsers";
    }
    if (documentType === "others") {
      registrationForm = registrationForm + "OtherDocuments";
    }
    return RegisterType[registrationForm as keyof typeof RegisterType];
  }

  get canSubmit(): boolean {
    for (const requiredField of this.dynamicForm.requiredFields) {
      if (!this.form[requiredField]) {
        return false;
      }
    }

    const errors = Object.values(this.validationErrors);
    const thereAreErrors = errors.some(e => e !== "");
    if (thereAreErrors) {
      return false;
    }

    return true;
  }

  public async change(field: string) {
    await this.validate(field);
  }

  public async cancel(): Promise<void> {
    await cancelOnboarding();
  }

  public async accept(): Promise<void> {
    this.submitted = true;
    const onboardingId = this.$appStore.getters.onboardingId;
    const identityPortrayalId = this.$appStore.getters.identityPortrayalId;

    const data: DynamicData = {
      fields: Object.fromEntries(
        Object.entries(this.form).filter(([fieldName]) => !this.dynamicForm.findField(fieldName)?.extra)
      ),
      extraFields: Object.fromEntries(
        Object.entries(this.form).filter(([fieldName]) => this.dynamicForm.findField(fieldName)!.extra)
      ),
      deliver_by_mail: false,
      email: "",
    };

    const documentType = this.$appStore.getters.documentType;
    if (documentType === "others") {
      const field = "document_type";
      data.fields[field] = "others";
    }

    const result = await this.$services.onboardingService.confirm({
      onboardingId,
      identityPortrayalId,
      data,
    });

    if (result.success) {
      if (this.$appStore.getters.settings.postEnrolmentAction === "accredit_proof") {
        this.$router.push({ name: RouteNames.AccreditProof });
      } else {
        if (this.$appStore.getters.biometricQR) {
          this.$router.push({
            name: RouteNames.FinishRegisterWithQr,
            params: {
              userId: result.userId,
              qrId: result.qrId ? result.qrId : "",
            },
          });
        } else {
          this.$router.push({ name: RouteNames.FinishRegister, params: { userId: result.userId } });
        }
      }
    } else {
      this.$router.push({
        name: RouteNames.FinishRegisterWithError,
        params: { errorCode: result.code.toString() },
      });
    }
    this.$appStore.actions.resetOnboarding();
  }

  public getTranslatedOptions(selectOptions: SelectOption[]) {
    if (selectOptions !== undefined) {
      return selectOptions.map(({ value, label }) => ({ label: this.$t("common.fields." + label), value }));
    }

    return undefined;
  }

  public async validate(field: string) {
    const error = this.$tc(await this.dynamicForm.validate(field, this.form[field]));
    this.$set(this.validationErrors, field, error);
  }
}
