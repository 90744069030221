import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { DgBadge, DgColumn, DgRow, DgText } from "@dasgate/uikit";

@Component({ components: { DgText, DgBadge, DgColumn, DgRow } })
export default class Step extends Vue {
  @Prop({ required: true }) public index!: number;
  @Prop({ required: false, default: false }) public title!: string;
  @Prop({ required: false, default: false }) public description!: string;
}
