import { Vue } from "vue-property-decorator";
import Component from "vue-class-component";
import { RouteNames } from "@/ui/_router";
import { DgBox, DgButton, DgColumn, DgFlex, DgImage, DgSeparator, DgStepper, DgText } from "@dasgate/uikit";
import { OnboardingSteps } from "@/legacy/models/settings";
import { getStepper } from "@/ui/Onboarding/Tutorial/Tutorial.model";
import { cancelOnboarding } from "@/legacy/features/onboarding/models/onboarding.models";

@Component({
  components: {
    DgText,
    DgButton,
    DgStepper,
    DgImage,
    DgColumn,
    DgSeparator,
    DgBox,
    DgFlex,
  },
})
export default class IdentityProofingStep extends Vue {
  get stepper() {
    return getStepper(this.$appStore.getters.settings.onboardingSteps, OnboardingSteps.IdProofing);
  }

  public continueOnboarding(): void {
    this.$router.push({ name: RouteNames.RegisterDocuments });
  }

  public get showCancel(): boolean {
    const isFirstStep =
      this.$appStore.getters.settings
        .onboardingSteps!.filter(step => step !== OnboardingSteps.ScanQr)
        .findIndex(step => step === OnboardingSteps.IdProofing) === 0;
    return !(isFirstStep && !this.$appStore.getters.settings.showNextSteps);
  }

  private async onCancel() {
    await cancelOnboarding();
  }
}
