import { render, staticRenderFns } from "./TakeSelfieAlive.vue?vue&type=template&id=17f603dc&scoped=true&"
import script from "./TakeSelfieAlive.ts?vue&type=script&lang=ts&"
export * from "./TakeSelfieAlive.ts?vue&type=script&lang=ts&"
import style0 from "./TakeSelfieAlive.scss?vue&type=style&index=0&id=17f603dc&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "17f603dc",
  null
  
)

export default component.exports