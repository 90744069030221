import Vue from "vue";
import Component from "vue-class-component";
import { DgBox, DgButton, DgColumn, DgIcon, DgLoading, DgRow, DgText } from "@dasgate/uikit";
import { RouteNames } from "@/ui/_router";
import { isApiError } from "@/core/shared/api/domain/apiClient";
import { getSubtitleKey, getTitleKey } from "./TakeSelfie.model";

@Component({ components: { DgText, DgIcon, DgButton, DgLoading, DgRow, DgBox, DgColumn } })
export default class TakeSelfie extends Vue {
  public sdkStatus = {
    mounted: false,
    error: false,
    cameraFailure: false,
  };
  public selfieStatus = {
    error: false,
    errorReason: "unknown",
  };

  get title() {
    return this.$t(getTitleKey(this.selfieStatus.errorReason));
  }

  get subtitle() {
    return this.$t(getSubtitleKey(this.selfieStatus.errorReason));
  }

  public get done(): boolean {
    return this.sdkStatus.error || (!this.sdkStatus.mounted && this.selfieStatus.error);
  }

  public destroyed() {
    this.destroySdk();
  }

  public mounted() {
    this.launchSdk();
  }

  public go(): void {
    this.$router.push({ name: RouteNames.ReviewRegister }).catch(() => null);
  }

  public cancel(): void {
    this.destroySdk();
    if (this.selfieStatus.error) {
      this.$router.push({ name: RouteNames.DocumentScan }).catch(() => null);
    } else {
      this.$router.push({ name: RouteNames.RegisterSelfie }).catch(() => null);
    }
  }

  private async onSelfieTaken(image: string) {
    this.$execute(() => this.$container.portrayalUseCases.sendSelfie(image), {
      fallbackLocation: { name: RouteNames.ReviewRegister },
      onError: error => {
        this.selfieStatus.error = true;
        if (isApiError(error) && error.data?.reason) {
          this.selfieStatus.errorReason = error.data?.reason;
        }
      },
    });
  }

  private onSdkMounted() {
    this.sdkStatus.mounted = true;
  }

  private onSdkFinish() {
    this.sdkStatus.mounted = false;
  }

  private onSdkError(fatal: boolean) {
    if (fatal) {
      this.sdkStatus.error = true;
    } else {
      this.sdkStatus.cameraFailure = true;
    }
  }

  private launchSdk() {
    const container = this.$refs.selfieContainer as Element;
    this.$services.takeSelfieService.take({
      container,
      containerSelector: ".sdkContainer",
      primaryColor: this.$services.colorService.getPrimaryColor(),
      onSelfie: this.onSelfieTaken,
      onStart: this.onSdkMounted,
      onFinish: this.onSdkFinish,
      onError: this.onSdkError,
    });
  }

  private destroySdk() {
    if (this.$refs.selfieContainer) {
      this.$services.takeSelfieService.cancel();
    }
  }
}
