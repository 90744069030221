import Vue from "vue";
import Component from "vue-class-component";
import { DgButton, DgColumn, DgIcon, DgLoading, DgText } from "@dasgate/uikit";
import { RouteNames } from "@/ui/_router";
import { toFormData } from "@/ui/Onboarding/TakeSelfieAlive/TakeSelfieAlive.model";
import { isApiError } from "@/core/shared/api/domain/apiClient";
import { getSubtitleKey, getTitleKey } from "./TakeSelfieAlive.model";

@Component({ components: { DgText, DgButton, DgIcon, DgColumn, DgLoading } })
export default class TakeSelfieAlive extends Vue {
  public sdkStatus = {
    mounted: false,
    error: false,
    cameraFailure: false,
  };

  public selfieStatus = {
    sent: false,
    error: false,
    errorReason: "unknown",
  };

  get title() {
    return this.$t(getTitleKey(this.selfieStatus.errorReason));
  }

  get subtitle() {
    return this.$t(getSubtitleKey(this.selfieStatus.errorReason));
  }

  public get done(): boolean {
    const selfieDone = this.selfieStatus.sent || this.selfieStatus.error;
    return this.sdkStatus.error || (!this.sdkStatus.mounted && selfieDone);
  }

  public async mounted() {
    await this.launchSdk();
  }

  public destroyed() {
    this.destroySdk();
  }

  public go(): void {
    this.$router.push({ name: RouteNames.ReviewRegister }).catch(() => null);
  }

  public cancel(): void {
    this.destroySdk();
    if (this.selfieStatus.error) {
      this.$router.push({ name: RouteNames.DocumentScan }).catch(() => null);
    } else {
      this.$router.push({ name: RouteNames.RegisterSelfie }).catch(() => null);
    }
  }

  private async onSelfieTaken() {
    this.selfieStatus.sent = false;
  }

  private async onSelfieAliveTaken({ image, image_alive }: any) {
    this.$execute(() => this.$container.portrayalUseCases.sendSelfie(image, image_alive), {
      fallbackLocation: { name: RouteNames.ReviewRegister },
      onError: error => {
        this.selfieStatus.error = true;
        if (isApiError(error) && error.data?.reason) {
          this.selfieStatus.errorReason = error.data?.reason;
        }
      },
    });
  }

  onSelfieAliveProTaken = async (aliveRequest: any, challengeToken: string) => {
    const formData = toFormData(aliveRequest, challengeToken);
    this.$execute(() => this.$container.portrayalUseCases.sendLifeProof(formData), {
      fallbackLocation: { name: RouteNames.ReviewRegister },
      onError: error => {
        this.selfieStatus.error = true;
        if (isApiError(error) && error.data?.reason) {
          this.selfieStatus.errorReason = error.data?.reason;
        }
      },
    });
  };

  private async onCreateAliveChallenge() {
    const sapConfig =
      this.$appStore.getters.registerMode === "auto"
        ? this.$appStore.getters.settings.sapConfigAuto
        : this.$appStore.getters.settings.sapConfigAssisted;
    return sapConfig
      ? await this.$services.identityPortrayalService.createChallenge(sapConfig.length, sapConfig.expiration)
      : "";
  }

  private onSdkMounted() {
    this.sdkStatus.mounted = true;
  }

  private onSdkFinish() {
    this.sdkStatus.mounted = false;
  }

  private onSdkError(fatal: boolean) {
    if (fatal) {
      this.sdkStatus.error = true;
    } else {
      this.sdkStatus.cameraFailure = true;
    }
  }

  public async launchSdk() {
    const container = this.$refs.selfieContainer as Element;

    const aliveChallenge = await this.onCreateAliveChallenge();

    this.$services.takeSelfieAliveService.take({
      container,
      containerSelector: ".sdkContainer",
      primaryColor: this.$services.colorService.getPrimaryColor(),
      aliveChallenge,
      onSelfie: this.onSelfieTaken,
      onSelfieAlive: this.onSelfieAliveTaken,
      onSelfieAlivePro: (aliveRequest: any) => this.onSelfieAliveProTaken(aliveRequest, aliveChallenge),
      onStart: this.onSdkMounted,
      onFinish: this.onSdkFinish,
      onError: this.onSdkError,
      onCreateAliveChallenge: this.onCreateAliveChallenge,
    });
  }

  private destroySdk() {
    if (this.$refs.selfieContainer) {
      this.$services.takeSelfieAliveService.cancel();
    }
  }
}
