import Vue from "vue";
import Component from "vue-class-component";
import { DgColumn, DgSeparator, DgStepper, DgTakeSelfieView, TakeSelfieViewTranslations } from "@dasgate/uikit";
import { RouteNames } from "@/ui/_router";
import { redirectUser } from "@/legacy/features/common/shared";
import { SdkLoader } from "@/ui/Onboarding/Tutorial/SelfieInfoStep/sdkLoader";
import { PortrayalProcess, PortrayalType } from "@/core/portrayal/domain/portrayal";
import { getStepper, hasStepper } from "@/ui/Onboarding/Tutorial/Tutorial.model";
import { OnboardingSteps } from "@/legacy/models/settings";
import { authPermissions, AuthPermissions } from "@/core/user/domain/auth";

@Component({
  components: { DgStepper, DgColumn, DgSeparator, DgTakeSelfieView },
})
export default class SelfieInfoStep extends Vue {
  private sdk = new SdkLoader();
  public isSdkLoaded = false;

  mounted() {
    this.sdk.load(() => {
      this.isSdkLoaded = true;
    });
  }

  public get permissions(): AuthPermissions {
    return authPermissions(this.$container.authRepository.get());
  }

  get stepper() {
    return getStepper(this.$appStore.getters.settings.onboardingSteps, OnboardingSteps.Selfie);
  }

  get process(): PortrayalProcess {
    return (this.$route.query.process ?? "onboarding") as PortrayalProcess;
  }

  public async proceed() {
    if (this.$container.portrayalStorage.get().portrayalId) {
      this.go();
    } else {
      await this.startSelfiePortrayal();
    }
  }

  public async cancel(): Promise<void> {
    const onboardingId = this.$appStore.getters.onboardingId;
    if (onboardingId) {
      await this.$services.onboardingService.stop({ onboardingId });
      this.$appStore.actions.resetOnboarding();
    }
    await redirectUser();
  }

  private startSelfiePortrayal() {
    this.$execute(() => this.$container.portrayalUseCases.start(PortrayalType.Selfie, this.process));
  }

  private go(): void {
    if (this.$appStore.getters.lifeProofing) {
      this.$router.push({ name: RouteNames.TakeSelfieAlive });
    } else {
      this.$router.push({ name: RouteNames.TakeSelfie });
    }
  }

  get takeSelfieTraductions(): Record<TakeSelfieViewTranslations, string> {
    return {
      title: this.$t("common.onboarding.register-selfie.title") as string,
      message: this.$t("common.onboarding.register-selfie.description") as string,
      continue: this.$t("common.continue") as string,
      retry: this.$t("common.retry") as string,
      cancel: this.$t("common.exit") as string,
    } as Record<TakeSelfieViewTranslations, string>;
  }

  get showCancel() {
    return !(
      this.permissions.isUser() &&
      !hasStepper(this.$appStore.getters.settings.onboardingSteps, OnboardingSteps.IdProofing)
    );
  }
}
