import Vue from "vue";
import Component from "vue-class-component";
import { DgButton, DgColumn, DgIcon, DgLoading, DgText } from "@dasgate/uikit";
import { Log } from "@/legacy/infrastructure/logger";
import { RouteNames } from "@/ui/_router";

@Component({ components: { DgText, DgButton, DgColumn, DgLoading, DgIcon } })
export default class DocumentScan extends Vue {
  public get viewStatus() {
    if (this.done) {
      if (this.scanOk) {
        return "success";
      } else {
        return "error";
      }
    }
    if (this.sdkUnmounted) {
      return "loading";
    }
    return "sdk";
  }
  public error = false;
  public errorReason = "unknown";
  public cameraFailure = false;
  public sdkUnmounted = true;
  private frontImage = "";
  private reverseImage = "";
  private frontImageSent = false;
  private reverseImageSent = false;
  private obverseType = "";
  private reverseType = "";

  public async mounted() {
    this.$watch("done", () => {
      if (this.scanOk && this.done) {
        this.go();
      }
    });
    const container = this.$refs.scanContainer as Element;
    const documentType = this.$appStore.getters.documentType;
    const documentCountry = this.$appStore.getters.documentCountry;
    const onObverse = async (image: string) => {
      try {
        const identityPortrayalId = this.$appStore.getters.identityPortrayalId;
        await this.$services.identityPortrayalService.sendImage({
          identityPortrayalId,
          imageType: "document-obverse",
          image,
        });
        this.frontImage = image;
      } finally {
        this.frontImageSent = true;
      }
    };

    const onObverseTypeDetected = async (type: string) => {
      Log.info("obverse_type: " + type);
      this.obverseType = type;
    };

    const onReverseTypeDetected = async (type: string) => {
      Log.info("reverse_type: " + type);
      this.reverseType = type;
    };

    const onReverse = async (image: string) => {
      try {
        // TODO: use specific code from validas if exists??
        const identityPortrayalId = this.$appStore.getters.identityPortrayalId;
        await this.$services.identityPortrayalService.sendImage({
          identityPortrayalId,
          imageType: "document-reverse",
          image,
        });
        this.reverseImage = image;
      } catch (error) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        this.errorReason = error.response.data.reason;
      } finally {
        this.reverseImageSent = true;
      }
    };
    const onStart = () => {
      this.sdkUnmounted = false;
    };
    const onFinish = () => {
      this.sdkUnmounted = true;
    };
    const onError = (fatal: boolean) => {
      if (fatal) {
        this.error = true;
      } else {
        this.cameraFailure = true;
      }
    };

    this.$services.documentScanService.scan({
      container,
      containerSelector: ".documentscan__container",
      documentType,
      documentCountry,
      primaryColor: this.$services.colorService.getPrimaryColor(),
      onObverse,
      onReverse,
      onStart,
      onFinish,
      onError,
      onObverseTypeDetected,
      onReverseTypeDetected,
    });
  }

  public async destroyed() {
    this.destroyWidget();
  }

  public get done(): boolean {
    const documentType = this.$appStore.getters.documentType;
    const allImagesCaptured =
      documentType === "id_card" ? this.frontImageSent && this.reverseImageSent : this.frontImageSent;
    return this.error || (this.sdkUnmounted && allImagesCaptured);
  }

  public get scanOk(): boolean {
    const documentType = this.$appStore.getters.documentType;
    return documentType === "id_card" ? !!this.frontImage && !!this.reverseImage : !!this.frontImage;
  }

  public go(): void {
    this.destroyWidget();
    this.$router.push({ name: RouteNames.RegisterSelfie }).catch(() => null);
  }

  public async cancel(): Promise<void> {
    this.destroyWidget();
    this.$router.push({ name: RouteNames.RegisterDocuments }).catch(() => null);
  }

  private destroyWidget() {
    if (this.$refs.scanContainer) {
      this.$services.documentScanService.cancel();
    }
  }
}
