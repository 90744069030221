import { SendSelfieErrors } from "@/core/portrayal/domain/portrayalErrors";

const errorKeyMap = {
  [SendSelfieErrors.BlurredImageError]: "common.onboarding.register-documents.scan-ko.blurred_image_error",
  [SendSelfieErrors.DocumentClassificationError]:
    "common.onboarding.register-documents.scan-ko.document_classification_error",
  [SendSelfieErrors.ValidasError]: "common.onboarding.register-documents.scan-ko.validas_error",
  default: "common.onboarding.register-documents.scan-ko.unknown",
};

const getBaseTranslationKey = (reason: string) => errorKeyMap[reason] ?? errorKeyMap.default;

export const getTitleKey = (reason: string) => `${getBaseTranslationKey(reason)}.title`;
export const getSubtitleKey = (reason: string) => `${getBaseTranslationKey(reason)}.subtitle`;
