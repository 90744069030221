import Vue from "vue";
import { emailValidator } from "@/ui/_forms/validation";

export class FieldValidator {
  // eslint-disable-next-line no-undef
  [id: string]: (...args: any[]) => string | Promise<string>;

  constructor() {
    this[FieldValidatorEnum.validateDniOrNie] = validateDniOrNie;
    this[FieldValidatorEnum.validateNifOrNieChecksum] = validateNifOrNieChecksum;
    this[FieldValidatorEnum.validateEmail] = validateEmail;
    this[FieldValidatorEnum.validateEmailFormat] = validateEmailFormat;
    this[FieldValidatorEnum.validateAcsid] = validateAcsid;
    this[FieldValidatorEnum.validateFutureDate] = validateFutureDate;
    this[FieldValidatorEnum.validateWiegand] = validateWiegand;
  }
}

export enum FieldValidatorEnum {
  validateDniOrNie = "validateDniOrNie",
  validateEmail = "validateEmail",
  validateEmailFormat = "validateEmailFormat",
  validateAcsid = "validateAcsid",
  validateFutureDate = "validateFutureDate",
  validateWiegand = "validateWiegand",
  validateNifOrNieChecksum = "validateNifOrNieChecksum",
}

export function isMinuscule(letter: string): boolean {
  const letterRegex = /^[a-z]/;
  return letterRegex.test(letter);
}

export function ensureIsAlphaNumeric(field: string): string {
  const regex = new RegExp("^[A-Z0-9]+$");
  if (regex.test(field)) {
    return "";
  }

  return "common.errors.only_alphanumeric";
}

export function validateMXIdNumber(idNumber: string): string {
  const idNumberRegex = new RegExp("^[A-Z]{6}[0-9]{8}[A-Z]{1}[0-9]{3}$");
  if (idNumberRegex.test(idNumber)) {
    return "";
  }
  return "common.errors.idcard_format";
}

export function validateDniOrNie(idNumber: string): string {
  let inputNumber;
  let lett;
  let letter;
  const idNumberRegex = /^[XYZ]?\d{5,8}[A-Z]$/;

  if (idNumberRegex.test(idNumber)) {
    inputNumber = idNumber.substr(0, idNumber.length - 1);
    inputNumber = inputNumber.replace("X", "0");
    inputNumber = inputNumber.replace("Y", "1");
    inputNumber = inputNumber.replace("Z", "2");
    inputNumber = parseInt(inputNumber, 10);
    lett = idNumber.substr(idNumber.length - 1, 1);
    inputNumber = inputNumber % 23;
    letter = "TRWAGMYFPDXBNJZSQVHLCKET";
    letter = letter.substring(inputNumber, inputNumber + 1);

    if (letter === lett) {
      return "";
    } else {
      return "common.errors.idcard_letter";
    }
  }
  const lastChar = idNumber.substring(idNumber.length - 1);
  if (isMinuscule(lastChar)) {
    return "common.errors.dni_letter_min";
  }

  return "common.errors.idcard_format";
}

export function validateNifOrNieChecksum(idNumber: string): string {
  let inputNumber;
  let lett;
  let letter;
  // const idNumberRegex = /^[XYZ]?\d{5,8}[A-Z]$/;
  const nifRgex = /(?:\d){8}[A-Z]{1}$/;
  const nieRegex = /^[XYZ](?:\d){7,8}[A-Z]{1}$/;

  if (nifRgex.test(idNumber) || nieRegex.test(idNumber)) {
    inputNumber = idNumber.substr(0, idNumber.length - 1);
    inputNumber = inputNumber.replace("X", "0");
    inputNumber = inputNumber.replace("Y", "1");
    inputNumber = inputNumber.replace("Z", "2");
    inputNumber = parseInt(inputNumber, 10);
    lett = idNumber.substr(idNumber.length - 1, 1);
    inputNumber = inputNumber % 23;
    letter = "TRWAGMYFPDXBNJZSQVHLCKET";
    letter = letter.substring(inputNumber, inputNumber + 1);

    if (letter === lett) {
      return "";
    } else {
      return "common.errors.idcard_letter";
    }
  }

  return "";
}

const idCardValidators: { [K: string]: (idNumber: string) => string } = {
  ES_id_card: validateDniOrNie,
  ES_residence_permit: validateDniOrNie,
};

/**
 * Returns the most precise validator for the requested country
 *
 * @param name The validator identifier using country and id_card
 * @returns The validator for the required id
 */
export function validateId(name: string) {
  if (idCardValidators[name]) {
    return idCardValidators[name];
  }

  return undefined;
}

export async function validateEmail(email: string): Promise<string> {
  // tslint:disable-next-line:max-line-length
  const regexp = new RegExp(
    // eslint-disable-next-line no-useless-escape
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
  if (regexp.test(email)) {
    const res = await Vue.prototype.$services.delegatedOnboardingService.emailExists({ email });
    return res ? "common.errors.email_exists" : "";
  }
  return "common.errors.email_format";
}

export const validateEmailFormat = (email: string) => (emailValidator(email) ? "" : "common.errors.email_format");

export async function validateAcsid(acsid: string): Promise<string> {
  const result = await Vue.prototype.$services.delegatedOnboardingService.acsidExists({ acsid });
  return result ? "common.errors.acsid_invalid" : "";
}

export function validateFutureDate(date: Date): string {
  const now = new Date();
  now.setHours(0, 0, 0, 0);
  if (date.getTime() >= now.getTime()) {
    return "";
  }
  return "common.errors.future_date";
}

export function validateWiegand(credentialId: string): string {
  const regex = new RegExp(
    "^(0|[1-9][0-9]{0,3}|[1-5][0-9]{4}|6[0-4][0-9]{3}|65[0-4][0-9]{2}|655[0-2][0-9]|6553[0-5])$"
  );
  if (!regex.test(credentialId)) {
    return "common.errors.wiegand_format_error";
  }
  const num: number = +credentialId;
  if (num >= 1 && num <= 65535) {
    return "";
  }

  return "common.errors.wiegand_format_error";
}
