import { PortrayalResult } from "@/core/portrayal/domain/portrayal";
import { validateId } from "@/legacy/models/validators";
import Vue from "vue";
import i18n from "@/legacy/infrastructure/i18n";
import { isUndefined } from "@/core/shared/utils";

export const validationErrors = (portrayalResult: PortrayalResult) => {
  const docIdValidationError: string = getDocIdValidationError(portrayalResult);
  const lastNameValidationError = getLastNameValidationError(portrayalResult);
  return {
    firstName: portrayalResult?.documentData?.firstName ? "" : i18n.t("common.errors.required").toString(),
    lastName: lastNameValidationError,
    documentId: docIdValidationError,
  };
};

const getDocIdValidationError = (portrayalResult: PortrayalResult) => {
  if (isUndefined(portrayalResult?.scores?.dataValidationOk) || isUndefined(portrayalResult?.documentData?.documentId))
    return "";
  let docIdValidationError = "";
  if (portrayalResult?.documentData?.documentType === "passport") {
    if (portrayalResult?.documentData?.documentId) {
      docIdValidationError = validateIdNumber(
        portrayalResult.documentData.documentId,
        portrayalResult.documentData.documentType
      );
    }
  } else {
    docIdValidationError = portrayalResult?.documentData?.documentId
      ? validateIdNumber(portrayalResult.documentData.documentId, portrayalResult.documentData.documentType)
      : i18n.t("common.errors.required").toString();
  }
  return docIdValidationError;
};

const getLastNameValidationError = (portrayalResult: PortrayalResult) => {
  if (isUndefined(portrayalResult?.documentData?.lastName)) return "";
  return portrayalResult?.documentData?.lastName ? "" : i18n.t("common.errors.required").toString();
};

const validateIdNumber = (documentId: string, documentType: string): string => {
  const name = Vue.prototype.$appStore.getters.documentCountry + "_" + documentType;
  const validationFunction = validateId(name);
  if (validationFunction) {
    return i18n.t(validationFunction(documentId)).toString();
  }
  return "";
};
