import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { DgButton, DgColumn, DgImage, DgText } from "@dasgate/uikit";
import { Link } from "@/core/shared/api/domain/apiClient";
import { getSubtitleKey, getTitleKey } from "@/ui/Error/Error.model";
import { isDefined } from "@/core/shared/utils";
import { redirectUser } from "@/legacy/features/common/shared";

@Component({ components: { DgColumn, DgText, DgImage, DgButton } })
export default class Error extends Vue {
  @Prop({ required: false }) public link!: string | undefined;
  @Prop({ required: true }) public reason!: string;

  get title() {
    return this.$t(getTitleKey(this.reason));
  }

  get subtitle() {
    return this.$t(getSubtitleKey(this.reason));
  }

  get serializedLink(): Link | undefined {
    return isDefined(this.link) && JSON.parse(this.link);
  }

  public onRetry() {
    this.$router.push({ path: this.serializedLink!.uri, query: this.serializedLink!.params });
  }

  public async onBack() {
    this.$appStore.actions.resetOnboarding();
    await redirectUser();
  }
}
