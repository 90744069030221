const selfieSdkScript = "/sdk/selfie/VDSelfie_2_10_1.js";
const selfieAliveSdkScript = "/sdk/alive/VDAlive_3_5_0.js";
const openCvScript = "/sdk/alive/opencv/cv_alive.js";

export class SdkLoader {
  private sdkStatus = {
    selfieSDKLoaded: false,
    selfieAliveSDKLoaded: false,
    openCVLoaded: false,
  };

  private get isSdkLoaded(): boolean {
    return this.sdkStatus.selfieSDKLoaded && this.sdkStatus.selfieAliveSDKLoaded && this.sdkStatus.openCVLoaded;
  }

  public load(onLoadCallback: () => void) {
    if (this.isSdkOnWindow()) {
      onLoadCallback();
    }
    this.loadScript(selfieSdkScript, "selfie");
    this.loadScript(selfieAliveSdkScript, "selfie_alive");
    this.loadScript(openCvScript, "cv_alive");
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const documentHTMLElem: HTMLElement = document.getElementById("selfie") as HTMLElement;
    documentHTMLElem.onload = () => {
      this.sdkStatus.selfieSDKLoaded = true;
      if (this.isSdkLoaded) {
        onLoadCallback();
      }
    };
    const doclibHTMLElem: HTMLElement = document.getElementById("selfie_alive") as HTMLElement;
    doclibHTMLElem.onload = () => {
      this.sdkStatus.selfieAliveSDKLoaded = true;
      if (this.isSdkLoaded) {
        onLoadCallback();
      }
    };
    const cvHTMLElem: HTMLElement = document.getElementById("cv_alive") as HTMLElement;
    cvHTMLElem.onload = () => {
      this.sdkStatus.openCVLoaded = true;
      if (this.isSdkLoaded) {
        onLoadCallback();
      }
    };
  }

  private loadScript(src: string, name: string) {
    if (document.getElementById(name)) {
      return;
    }
    const script = document.createElement("script");
    script.setAttribute("src", src);
    script.setAttribute("type", "text/javascript");
    script.setAttribute("id", name);
    document.head.appendChild(script);
  }

  private isSdkOnWindow() {
    try {
      if ((window as any).vdalive.getSDKversion) {
        return true;
      }
      return false;
    } catch {
      return false;
    }
  }
}
