import { DgRadioGroup, DgColumn, DgImage, DgText, DgSeparator } from "@dasgate/uikit";
import Vue from "vue";
import Component from "vue-class-component";
import { Emit, Prop } from "vue-property-decorator";

@Component({ components: { DgRadioGroup, DgImage, DgColumn, DgText, DgSeparator } })
export default class SeveralDocuments extends Vue {
  @Prop({ required: true }) public countryDocuments!: any;
  @Prop({ required: true }) public documentType!: string;

  @Emit() onChange() {
    return;
  }
}
