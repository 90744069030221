import Vue from "vue";
import Component from "vue-class-component";
import moment from "moment-timezone";
import { BaseAssetsUrl } from "@/legacy/models/constants";
import { RouteNames } from "@/ui/_router";
import { validateEmailFormat } from "@/legacy/models/validators";
import {
  DgBox,
  DgButton,
  DgCheckbox,
  DgColumn,
  DgDatePicker,
  DgFlex,
  DgRow,
  DgSelect,
  DgSeparator,
  DgStepper,
  DgText,
  DgTextInput,
  DgTimePicker,
} from "@dasgate/uikit";
import { NewVisitRequest } from "@/core/visit/domain/visitRepository";
import { Facility } from "@/core/facility/domain/facility";
import { executorResult } from "@/ui/_utils/executor";
import { isDefined, isEmpty, isUndefined } from "@/core/shared/utils";

interface ProcessVisitForm {
  host: string;
  location: string;
  date: string;
  startTime: string;
  endTime: string;
  title: string;
  isPlanAccepted: boolean;
}

@Component({
  components: {
    DgText,
    DgTextInput,
    DgTimePicker,
    DgDatePicker,
    DgSelect,
    DgButton,
    DgBox,
    DgColumn,
    DgRow,
    DgSeparator,
    DgCheckbox,
    DgStepper,
    DgFlex,
  },
})
export default class ProcessVisit extends Vue {
  public facilitiesResult = executorResult<Facility[]>([]);
  public validationErrors: { [id: string]: string } = {};

  public form: ProcessVisitForm = {
    host: "",
    location: "",
    date: "",
    startTime: "08:00",
    endTime: "10:00",
    title: "",
    isPlanAccepted: false,
  };

  public async mounted() {
    await this.$execute(this.$container.facilityUseCases.getFacilities, {
      resultRef: this.facilitiesResult,
    });
  }

  get facilityOptions() {
    return this.facilitiesResult.data.map(facility => ({ label: facility.alias, value: facility.id }));
  }

  get selectedFacility(): Facility | undefined {
    if (isEmpty(this.facilitiesResult.data) || isEmpty(this.form.location)) {
      return undefined;
    }
    return this.facilitiesResult.data!.find(({ id: facilityId }) => facilityId === this.form.location);
  }

  get isFacilitySelected(): boolean {
    return isDefined(this.selectedFacility);
  }

  get timezoneForSelection(): string | undefined {
    return this.selectedFacility ? this.selectedFacility.timezone : undefined;
  }

  get hasEmergencyPlan() {
    return this.$appStore.getters.settings.visits.mustAcceptPlan;
  }

  get emergencyPlanLink(): string {
    if (isUndefined(this.selectedFacility)) {
      return "";
    }
    return `${BaseAssetsUrl}${this.selectedFacility.id}/emergency_plan.pdf`;
  }

  get canSubmit() {
    const requiredFields = ["date", "startTime", "endTime", "host", "location"];
    const isMissingRequiredField = requiredFields.some(field => isEmpty(this.form[field]));
    if (isMissingRequiredField) {
      return false;
    }

    const thereAreErrors = Object.values(this.validationErrors).some(e => !isEmpty(e));
    if (thereAreErrors) {
      return false;
    }

    return !(this.hasEmergencyPlan && !this.form.isPlanAccepted);
  }

  get isVisitor(): boolean {
    return this.$appStore.getters.isVisitor;
  }

  get loading(): boolean {
    return this.$appStore.getters.loading;
  }

  public onSubmit() {
    this.validationErrors = {};

    const newVisitRequest: NewVisitRequest = {
      title: this.form.title,
      startAt: moment.tz(`${this.form.date}T${this.form.startTime}`, this.timezoneForSelection!).toISOString(),
      endAt: moment.tz(`${this.form.date}T${this.form.endTime}`, this.timezoneForSelection!).toISOString(),
      host: this.form.host,
      visitor: this.$route.params.userId ? this.$route.params.userId : this.$appStore.getters.userId,
      location: this.form.location,
      emergencyPlanAccepted: this.form.isPlanAccepted,
    };

    this.$execute(() => this.$container.visitUseCases.addNewVisit(newVisitRequest), {
      onSuccess: async () => {
        this.$services.notificationService.success(this.$tc("common.visits.notifications.new-visit.subtitle"));
        if (this.isVisitor) {
          await this.$router.push({ name: RouteNames.MyVisits });
        } else {
          this.$router.push({ name: RouteNames.VisitList });
        }
      },
      onError: () => {
        this.$services.notificationService.error(this.$tc("common.visits.notifications.new-visit.error"));
      },
    });
  }

  public resetCheckbox() {
    this.form.isPlanAccepted = false;
  }

  public validate(field: string) {
    if (field === "host") {
      this.validateHostEmail();
    }

    if (["startTime", "endTime"].includes(field)) {
      this.validateStartsBeforeEnds();
    }

    if (["date", "location"].includes(field)) {
      this.validateFutureDate();
    }

    if (["startTime", "date", "location"].includes(field)) {
      this.validateStartsBeforeNow();
    }
  }

  private validateHostEmail() {
    this.validationErrors.host = this.$tc(validateEmailFormat(this.form.host)).toString();
  }

  private validateStartsBeforeEnds() {
    if (!isEmpty(this.form.startTime) && !isEmpty(this.form.endTime) && this.form.startTime >= this.form.endTime) {
      this.validationErrors.endTime = this.$t("common.errors.end_date_not_supported").toString();
      return;
    }
    this.validationErrors.endTime = "";
  }

  private validateFutureDate() {
    if (!isEmpty(this.form.date) && !isFutureDate(this.form.date, this.timezoneForSelection!, "day")) {
      this.validationErrors.date = this.$t("common.errors.future_date").toString();
      return;
    }
    this.validationErrors.date = "";
  }

  private validateStartsBeforeNow() {
    if (
      !isEmpty(this.form.date) &&
      !isEmpty(this.form.startTime) &&
      !isFutureDate(`${this.form.date}T${this.form.startTime}`, this.timezoneForSelection!, "minute")
    ) {
      this.validationErrors.startTime =
        this.$t("common.errors.future_start_time").toString() + moment.tz(this.timezoneForSelection!).format("HH:mm");
      return;
    }
    this.validationErrors.startTime = "";
  }
  public async onCancel() {
    this.$router.push({ name: this.isVisitor ? RouteNames.MyVisits : RouteNames.VisitList });
  }
}

const isFutureDate = (isoString: string, timezone: string, granularity: "day" | "minute") =>
  moment.tz(isoString, timezone).isSameOrAfter(moment.tz(timezone), granularity);
