import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { DgDeviceDetect, DeviceDetectTranslations, DeviceDetectFeedbackType } from "@dasgate/uikit";
import { Loading } from "@/ui/_components/Loading";
import { goToOnboarding } from "@/legacy/features/common/shared";
import { BaseAssetsUrl } from "@/legacy/models/constants";

@Component({
  components: { DgDeviceDetect, Loading },
})
export default class DeviceDetect extends Vue {
  @Prop({ required: false, default: "" }) public userCode!: string;

  public isLoading = true;
  private innerUserCode = this.userCode;

  public mounted(): void {
    this.isLoading = true;
    this.getUserCode();
  }

  public get browsersUrl(): string {
    return `${BaseAssetsUrl}browsers.json`;
  }

  public get loginUrl(): string {
    return this.innerUserCode ? window.location.origin + "/?user_code=" + this.innerUserCode : window.location.origin;
  }

  public continueOnboarding(): void {
    goToOnboarding(this.$route.query);
  }

  private async getUserCode() {
    const response = await this.$services.authentication.genUserCode();
    this.innerUserCode = response.usercode;
    this.isLoading = false;
  }

  get t(): Record<DeviceDetectFeedbackType, Record<DeviceDetectTranslations, string>> {
    return {
      RedirectToMobile: {
        title: this.$t("common.onboarding.device-detect.redirect-to-mobile.title") as string,
        message: this.$t("common.onboarding.device-detect.redirect-to-mobile.message") as string,
        advise: this.$t("common.onboarding.device-detect.redirect-to-mobile.advise") as string,
        continue: this.$t("common.onboarding.device-detect.redirect-to-mobile.continue") as string,
      },
      UnsupportedBrowser: {
        title: this.$t("common.onboarding.device-detect.unsupported-browser.title") as string,
        message: this.$t("common.onboarding.device-detect.unsupported-browser.message") as string,
        advise: this.$t("common.onboarding.device-detect.unsupported-browser.advise") as string,
      },
      UpgradeBrowser: {
        title: this.$t("common.onboarding.device-detect.upgrade-browser.title") as string,
        message: this.$t("common.onboarding.device-detect.upgrade-browser.message") as string,
        advise: this.$t("common.onboarding.device-detect.upgrade-browser.advise") as string,
      },
    } as Record<DeviceDetectFeedbackType, Record<DeviceDetectTranslations, string>>;
  }
}
