import Vue from "vue";
import Component from "vue-class-component";
import { RouteNames } from "@/ui/_router";
import { DgButton, DgColumn, DgDivider, DgSeparator, DgText } from "@dasgate/uikit";
import { Step } from "./_components/Step";

@Component({ components: { DgText, DgDivider, Step, DgButton, DgSeparator, DgColumn } })
export default class NextSteps extends Vue {
  get steps() {
    return this.$appStore.getters.settings.onboardingSteps!.map(step => ({
      title: this.$t(`common.onboarding.next-steps.steps.${step}.title`),
      description: this.$t(`common.onboarding.next-steps.steps.${step}.description`),
    }));
  }

  public onContinue() {
    if (Vue.prototype.$appStore.getters.identityProofing) {
      this.$router.push({ name: RouteNames.IdentityProofingStep });
      return;
    }

    this.$router.push({ name: RouteNames.RegisterSelfie });
  }
}
